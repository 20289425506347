<template>
  <div class="add chat">
    <div class="add-top-title">
      <span @click="backHome()"><van-icon name="arrow-left" /></span>
      <span>编辑话术</span>
      <span></span>
    </div>
    <div>
      <div class="add-item-title">请输入问题：</div>
      <div :class="boxBorder">
        <van-field
                v-model="addData.title"
                rows="3"
                type="textarea"
                placeholder="请输入问题"
                @input="showNotice"
        />
        <div :class="numColor">{{num}}/40</div>
      </div>
      <div class="add-item-box add-item-margin">
        <van-field
                v-model="addData.content"
                rows="8"
                autosize
                type="textarea"
                maxlength="200"
                placeholder="请输入回答的话术"
        />
      </div>
      <div class="add-item-switch">
        <van-cell center title="置顶">
          <template #right-icon>
            <van-switch v-model="checked" size="20" />
          </template>
        </van-cell>
      </div>
    </div>
    <div class="bottom-margin"></div>
    <div class="bottom-box">
      <div @click="backHome()" class="bottom-button-1 bottom-button-padding">返回</div>
      <div @click="saveSpeechArt()" class="bottom-button-2 bottom-button-padding">保存</div>
      <div @click="delSpeechArt()" class="bottom-button-3">删除</div>
    </div>
  </div>
</template>

<script>
import { addWord, delWord } from '@/api/service'
import { Toast } from 'vant';
import { Dialog } from 'vant';
import Cookies from 'js-cookie'
export default {
  name: 'Add',
  components: {
  },
  data() {
    return {
      userId: '', // 用户id
      nowUserInfo: {}, // 当前用户信息
      checked: false,
      addData: {
        userid: '',
        title: '',
        content: '',
        toping: '',
        id: '',
        assType:1
      },
      boxBorder: 'add-item-box',
      num: 0,
      numColor: 'field-num'
    }},
  created() {
    let userId=Cookies.get('userId')
    const query = this.$route.query
    this.addData.userid = userId ? userId : ''
    this.addData.title = query.title ? query.title : ''
    this.num = this.addData.title.length
    this.addData.content = query.content ? query.content : ''
    this.addData.toping = query.toping ? query.toping : ''
    this.addData.id = query.id ? query.id : ''
    this.checked = query.toping == 1 ? true : false
    if(!userId){
      this.$router.push('/');
      return
    }
  },
  mounted:function(){

  },
  methods: {
    // 新增话术
    saveSpeechArt() {
      if(this.addData.userid === ''){
        Toast.fail('未获取到用户id');
        return
      }
      if(this.num > 40){
        Toast.fail('标题不能超过40字');
        return
      }
      if(this.addData.title === ''){
        Toast.fail('请先填写标题');
        return
      }
      if(this.addData.content === ''){
        Toast.fail('请先填写回答话术');
        return
      }
      this.addData.toping = this.checked === true ? 1 : 0;
      addWord(this.addData).then(response => {
        console.log(response)
        Toast.success('操作成功');
        this.$router.push('/');
      })
    },
    // 删除话术
    delSpeechArt() {
      if (this.addData.id === ''){
        Toast.fail('未选择话术');
        return
      }
      Dialog.confirm({
        title: '提示',
        message: '删除后将不能恢复，是否确认删除？',
      })
      .then(() => {
        // on confirm
        let postData = {}
        postData.id = this.addData.id
        delWord(postData).then(response => {
          Toast.success('删除成功');
          console.log(response)
          this.$router.push('/');
        })
      })
      .catch(() => {
        // on cancel
      })
    },
    // 监听是否输入超过字段限制
    showNotice() {
      let nowNum = this.addData.title.length
      this.num = nowNum
      if(nowNum > 40){
        this.boxBorder = 'add-item-box2'
        this.numColor = 'field-num1'
      }else{
        this.boxBorder = 'add-item-box'
        this.numColor = 'field-num'
      }
    },
    // 返回首页
    backHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style>
  .chat{
    background-color:#ffffff;
    padding: 20px;
  }
  .add-top-title{
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #0A0A0A;
    line-height: 20px;
    padding: 0px 0 15px 0;
    border-bottom: 1px dashed #d4d7d7;
    display: flex;
    justify-content: space-between;
  }
  .add-item-title{
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin: 15px 0;
  }
  .add-item-box{
    border: 1px solid #D9D9D9;
  }
  .add-item-box2{
    border: 1px solid #f53e3e;
  }
  .add-item-margin{
    margin-top: 15px;
  }
  .add-item-switch{
    margin-top: 15px;
    border-bottom: 1px solid #EEEEEE;
    border-top: 1px solid #EEEEEE;
  }
  .add .bottom-box{
    position: fixed;
    bottom: 60px;
    width: calc(100vw - 30px);
    display: flex;
    justify-content: center;
  }
  .add .bottom-button-1{
    width: 80px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    color: #D9D9D9;
    line-height: 32px;
    text-align: center;
  }
  .add .bottom-button-2{
    width: 80px;
    height: 32px;
    background: #1890FF;
    border-radius: 2px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
  }
  .add .bottom-button-3{
    width: 80px;
    height: 32px;
    background: #FF4D4F;
    border-radius: 2px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
  }
  .add .bottom-button-padding{
    margin-right: 10px;
  }
  .add .bottom-margin{
    height: 100px;
  }
  .field-num{
    margin-top: 4px;
    color: #646566;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    padding: 10px 16px;
  }
  .field-num1{
    margin-top: 4px;
    color: #f66c6c;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    padding: 10px 16px;
  }
  .add-item-box .van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ffffff !important;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
  .add-item-box2 .van-cell::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ffffff !important;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
</style>
